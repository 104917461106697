/**
 * Type: ページ
 * What: 団体利用LP
 */
import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import Image from '../../util/Image';
import { Button } from '../../components/Btn';

const pageTitle = 'イベントホール（貸しホール）利用料金';
const pageDescription = '6Fイベントホールは、多目的にご利用いただける貸ホールです。最先端のアートからサブカルチャーまで様々な展示会、イベント等でご利用いただけます。';
const pageSlug = 'eventhall-price';
// Data Props Template
const ticketHowPage = () => (
  <Layout pageSlug={pageSlug}>
    <SEO title={pageTitle} description={pageDescription} />
    <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
    <section className="section">
      <div className="container">
        <h3 className="headline">
          <span>利用料金</span>
        </h3>
        <h4 className="headline">
          <span>使用パターン</span>
        </h4>
        <div className="inner">
          <div className="sc-columns sp-mb10">
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhall-price-1.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    パターン①（全体）
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhall-price-2.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    パターン②（A+E）
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhall-price-3.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    パターン③（B+C+E）
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhall-price-4.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    パターン④（A）
                  </h5>
                </div>
              </div>
            </div>
            <div className="col col-6 col-schedules">
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="img-eventhall-price-5.jpg" />
                  </figure>
                </div>
                <div className="col-content">
                  <h5 className="schedule-title">
                    パターン⑤（B+C）
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <ul className='list-base mb0'>
            <li>上記パターン以外の貸出にも柔軟に対応致します</li>
            <li>1カ月未満の貸出をご希望の場合にはご相談ください</li>
          </ul>
          <p className="list-mark mb0">※消費税は別途申し受けします</p>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4 className="headline">
          <span>料金一覧表</span>
        </h4>
        <div className="inner-slim trans pb-0">
          <div className="slime-table">
            <div className="ticket-table">
              <div className="table-base price-table">
                <table>
                  <tbody>
                    <tr>
                      <th>パターン</th>
                      <th>面積</th>
                      <th>料金</th>
                    </tr>
                    <tr>
                      <td>パターン①（全体）</td>
                      <td>819.6㎡</td>
                      <td>¥6,000,000 / 月</td>
                    </tr>
                    <tr>
                      <td>パターン②（A+E）</td>
                      <td>374.9㎡</td>
                      <td>¥3,000,000 / 月</td>
                    </tr>
                    <tr>
                      <td>パターン③（B+C+E）</td>
                      <td>446.8㎡</td>
                      <td>¥3,000,000 / 月</td>
                    </tr>
                    <tr>
                      <td>パターン④（A）</td>
                      <td>303.7㎡</td>
                      <td>¥3,000,000 / 月</td>
                    </tr>
                    <tr>
                      <td>パターン⑤（B+C）</td>
                      <td>375.6㎡</td>
                      <td>¥3,000,000 / 月</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h4 className="headline">
          <span>附帯設備利用について</span>
        </h4>
        <div className="inner-slim trans pb-0">
          <div className="slime-table">
            <div className="ticket-table">
              <div className="table-base price-table">
                <table>
                  <tbody className="pc">
                    <tr>
                      <th className='ml-15'>項目</th>
                      <th className='ml-11'>料金（税抜）</th>
                      <th>備考</th>
                    </tr>
                    <tr>
                      <td>ご来場者向けフリーWi-Fi</td>
                      <td>無料</td>
                      <td className='tal'>
                        対象となるフリーWi-FiのSSID
                        <ul className='list-base mt0 mb0'>
                          <li>Free Wi-Fi Passport</li>
                          <li>0002softbank</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>インターネット回線</td>
                      <td>お客様にてご契約</td>
                      <td className='tal'>
                        <ul>
                          <li>NTT回線を指定としております。</li>
                          <li>お客様にて回線契約をお願いします。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>外線電話設置/利用</td>
                      <td>お客様にてご契約</td>
                      <td className='tal'>
                        <ul>
                          <li>NTT回線を指定としております。</li>
                          <li>お客様にて回線契約をお願いします。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>水道光熱料金</td>
                      <td>実費</td>
                      <td className='tal'>{' '}</td>
                    </tr>
                    <tr>
                      <td>清掃料金</td>
                      <td>実費</td>
                      <td className='tal'>
                        <ul>
                          <li>開催期間中の清掃にかかる費用。</li>
                          <li>開催後の現状回復清掃にかかる費用。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>長机/椅子</td>
                      <td>無料</td>
                      <td className='tal'>長机：最大10脚 / 椅子：最大20脚</td>
                    </tr>
                    <tr>
                      <td>スポットライト</td>
                      <td>無料</td>
                      <td className='tal'>最大160灯</td>
                    </tr>
                    <tr>
                      <td>夜間金庫/釣銭ロッカー</td>
                      <td>¥27,000 / 月</td>
                      <td className='tal'>売上金一時預かりに限り利用可能</td>
                    </tr>
                    <tr>
                      <td>POSレジ</td>
                      <td>{' '}</td>
                      <td className='tal'>販売手数料については別途協議</td>
                    </tr>
                    <tr>
                      <td>チケット販売</td>
                      <td>{' '}</td>
                      <td className='tal'>
                        <ul>
                          <li>BOSS E・ZO FUKUOKA 公式チケットサイトおよび現地券売機での販売。</li>
                          <li>販売手数料については別途協議。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>入館カード</td>
                      <td>無料</td>
                      <td className='tal'>
                        <ul>
                          <li>最大50枚。</li>
                          <li>入館者リストを事前にご提出いただき、日々入館の際に1F受付にて貸出させていただき、退館時に返却をお願いしております。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>会場内鍵</td>
                      <td>無料</td>
                      <td className='tal'>
                        <ul>
                          <li>最大50枚。</li>
                          <li>会場利用時に必要となる鍵は、日々入館の際に1F受付にて貸出させていただき、退館時に返却をお願いしております。</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                  <tbody className="sp ml-8">
                    <tr>
                      <th colSpan="2">ご来場者向けフリーWi-Fi</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>無料</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        対象となるフリーWi-FiのSSID
                        <ul className='list-base mt0 mb0 tal'>
                          <li>Free Wi-Fi Passport</li>
                          <li>0002softbank</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">インターネット回線</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>お客様にてご契約</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <ul className='list-base mt0 mb0 tal'>
                          <li>NTT回線を指定としております。</li>
                          <li>お客様にて回線契約をお願いします。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">外線電話設置/利用</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>お客様にてご契約</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <ul className='list-base mt0 mb0 tal'>
                          <li>NTT回線を指定としております。</li>
                          <li>お客様にて回線契約をお願いします。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">水道光熱料金</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>実費</td>
                    </tr>
                    <tr>
                      <th colSpan="2">清掃料金</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>実費</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <ul className='list-base mt0 mb0 tal'>
                          <li>開催期間中の清掃にかかる費用。</li>
                          <li>開催後の現状回復清掃にかかる費用。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">長机/椅子</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>無料</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>長机：最大10脚 / 椅子：最大20脚</td>
                    </tr>
                    <tr>
                      <th colSpan="2">スポットライト</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>無料</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>最大160灯</td>
                    </tr>
                    <tr>
                      <th colSpan="2">夜間金庫/釣銭ロッカー</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>¥27,000 / 月</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>売上金一時預かりに限り利用可能</td>
                    </tr>
                    <tr>
                      <th colSpan="2">POSレジ</th>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>販売手数料については別途協議</td>
                    </tr>
                    <tr>
                      <th colSpan="2">チケット販売</th>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <ul className='list-base mt0 mb0 tal'>
                          <li>BOSS E・ZO FUKUOKA 公式チケットサイトおよび現地券売機での販売。</li>
                          <li>販売手数料については別途協議。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">入館カード</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>無料</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <ul className='list-base mt0 mb0 tal'>
                          <li>最大50枚。</li>
                          <li>入館者リストを事前にご提出いただき、日々入館の際に1F受付にて貸出させていただき、退館時に返却をお願いしております。</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th colSpan="2">会場内鍵</th>
                    </tr>
                    <tr>
                      <td>料金（税抜）</td>
                      <td>無料</td>
                    </tr>
                    <tr>
                      <td>備　考</td>
                      <td>
                        <p className='tal'>会場利用時に必要となる鍵は、日々入館の際に1F受付にて貸出させていただき、退館時に返却をお願いしております。</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ticketHowPage;
